import React, {useEffect, useState} from 'react';
import Row from 'reactstrap/es/Row';
import {Button, Col} from 'reactstrap';
import {v4 as uuidv4} from 'uuid';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {checkIfObjectEmpty, reformatTelephoneNumber} from '../../Libs/object';
import {createBooking, updateBooking} from '../Bookings/actions';
import {
  makeSelectBookingId,
  makeSelectBookingMode,
  makeSelectCheckoutMode,
  makeSelectCustomer,
  makeSelectDiscount,
  makeSelectOperation,
  makeSelectProducts,
  makeSelectSelectedNotes,
} from '../../Components/Cart/selectors';
import {
  makeSelectHotel,
  makeSelectWalkInUser,
} from '../Authentication/selectors';
import {
  calculateRemaining,
  cartTotal,
  getDeliveryCharges,
  getDiscountPrice,
  getMinOrderAmount,
  toCents,
} from '../../Libs/prices';
import Numpad from './Numpad';
import Price from '../../Components/Price';
import CardPayment from './CardPayment';
import Print from '../../Components/Print';
import {
  makeSelectChange,
  makeSelectDisplayValue,
  makeSelectPayments,
  makeSelectSplitOrder,
} from './selectors';
import {
  removePayment,
  setChange,
  setDisplayValue,
  setPayment,
  setPayments,
  setSpliting,
  splitOrder,
} from './actions';
import CustomerAddress from './CustomerAddress';

function Payments({setState}) {
  const dispatch = useDispatch();
  const mode = useSelector(makeSelectCheckoutMode());
  const products = useSelector(makeSelectProducts());
  const operation = useSelector(makeSelectOperation());
  const walkInUser = useSelector(makeSelectWalkInUser());
  const notes = useSelector(makeSelectSelectedNotes());
  const payments = useSelector(makeSelectPayments());
  const displayValue = useSelector(makeSelectDisplayValue());
  const change = useSelector(makeSelectChange());
  const split = useSelector(makeSelectSplitOrder());
  const {branch} = useSelector(makeSelectHotel());
  const discount = useSelector(makeSelectDiscount());
  const customer = useSelector(makeSelectCustomer());
  const bookingId = useSelector(makeSelectBookingId());
  const bookingMode = useSelector(makeSelectBookingMode());
  const minOrderAmount = getMinOrderAmount(branch, mode);
  const cartTotalAmount = cartTotal(products, mode);
  const subTotal =
    minOrderAmount > cartTotalAmount ? minOrderAmount : cartTotalAmount;
  const discountPrice = getDiscountPrice(discount, subTotal);
  const deliveryCharges = getDeliveryCharges(operation, mode, subTotal);
  const total = subTotal - discountPrice + deliveryCharges;
  const remaining = calculateRemaining(total, payments);
  const [disabled, setDisabled] = useState(false);

  const handleDeletePayment = (i) => {
    dispatch(removePayment(i));
  };

  useEffect(() => {
    if (toCents(remaining) > 0) {
      dispatch(setChange(0));
    }
  }, [remaining]);

  const handleUpdate = () => {
    dispatch(
      updateBooking({
        booking_id: bookingId,
        branch_slug: branch.slug,
        checkout_mode: mode,
        sub_total: subTotal,
        discount_amount: discountPrice,
        delivery_charges: deliveryCharges,
        payments,
        formData: {
          ...customer,
          telephone: reformatTelephoneNumber(customer.telephone),
        },
        notes: notes.length ? notes.join(', ') : '',
        cart: products,
        bookingUnique: uuidv4(),
        syncState: 'syncing',
        checkout_time: moment().format('YY-MM-DD HH:mm'),
      }),
    );
    setState('success');
  };

  const handleCheckout = () => {
    setDisabled(true);
    let formData = {};
    if (mode === 'Takeaway') {
      formData = checkIfObjectEmpty(customer)
        ? {
            ...walkInUser,
            telephone: reformatTelephoneNumber(walkInUser.telephone),
          }
        : {...customer, telephone: reformatTelephoneNumber(customer.telephone)};
    } else if (mode === 'Delivery') {
      if (checkIfObjectEmpty(customer)) {
        alert('Customer Details not provided');
        setDisabled(false);
        return;
      }
      formData = customer;
    }

    if (products.length === 0) {
      alert('Cart is empty');
      setDisabled(false);
      return;
    }
    dispatch(
      createBooking({
        branch_slug: branch.slug,
        checkout_mode: mode,
        sub_total: subTotal,
        discount_amount: discountPrice,
        delivery_charges: deliveryCharges,
        payments,
        formData: {
          ...formData,
          telephone: reformatTelephoneNumber(formData.telephone),
        },
        notes: notes.length ? notes.join(', ') : '',
        cart: products,
        bookingUnique: uuidv4(),
        syncState: 'syncing',
        checkout_time: moment().format('YY-MM-DD HH:mm'),
      }),
    );
    setDisabled(false);
    setState('success');
  };

  const handleFuturePayment = (m, terminal) => {
    dispatch(setPayments([{mode: m, amount: total, terminal}], 0));
  };

  const handleAddPayment = (m, terminal) => {
    let c = 0;
    if (toCents(displayValue) > toCents(remaining)) {
      c = toCents(displayValue) - toCents(remaining);
    }
    dispatch(
      setPayment({mode: m, amount: toCents(displayValue) - c, terminal}, c),
    );
    if (split) {
      dispatch(setSpliting(true));
    }
  };
  return (
    <Row className="payment-dashboard">
      <Col>
        <Row>
          <h2 className="total-amount text-secondary">
            Total Amount: <Price>{total}</Price>{' '}
          </h2>
        </Row>
        <Row>
          <div className="payment-summary">
            {payments.map((payment, i) => (
              <div className="payment-row" key={`${payment.amount} ${i}`}>
                <div className="left">
                  <Price>{payment.amount}</Price> - {payment.mode}
                </div>
                <div className="right">
                  <i
                    className="fa fa-ban text-danger"
                    onClick={() => handleDeletePayment(i)}
                  />
                </div>
              </div>
            ))}
          </div>
        </Row>
        <Row>
          <Print />
          <Link to="/discounts" className="action-button bg-success text-light">
            <i className="fa fa-percent" />
            <span>DISCOUNTS</span>
          </Link>
          <Button
            className="action-button bg-danger"
            onClick={() => dispatch(splitOrder(!split))}
          >
            <i className="fa fa-scissors" />
            <span>DIVIDE TABLE</span>
          </Button>
          {/* <Button className="action-button bg-success"> */}
          {/*  <i className="fa fa-plus" /> */}
          {/*  <span>SERVICE CHARGE</span> */}
          {/* </Button> */}
        </Row>
        <Row>
          <Col className="addresses">
            <CustomerAddress />
          </Col>
        </Row>
      </Col>
      <Col className="ml-2">
        <Row>
          <h2 className="total-amount text-secondary">Pay</h2>
        </Row>
        <Row className="numpad-wrapper">
          <Numpad
            remaining={remaining}
            displayValue={displayValue}
            setDisplayValue={(value) => dispatch(setDisplayValue(value))}
            setSpliting={setSpliting}
          />
        </Row>
        {toCents(remaining) !== 0 && (
          <Row>
            <Button
              className="action-button bg-ternary"
              onClick={() => handleAddPayment('cash', '')}
            >
              <i className="fa fa-money" />
              <span>CASH</span>
            </Button>
            <CardPayment handleAddPayment={handleAddPayment} />
            {mode === 'Delivery' && (
              <Button
                className="action-button bg-warning"
                onClick={() => handleFuturePayment('pay on delivery', '')}
              >
                <i className="fa fa-truck" />
                <span>Pay On Delivery</span>
              </Button>
            )}
            {mode === 'Takeaway' && (
              <Button
                className="action-button bg-success"
                onClick={() => handleFuturePayment('pay on collection', '')}
              >
                <i className="fa fa-handshake-o" />
                <span>Pay On Collection</span>
              </Button>
            )}
          </Row>
        )}
        {change > 0 && (
          <Row style={{width: '320px'}}>
            <h5 className="change">
              Change:{' '}
              <strong className="price">
                <Price>{change}</Price>
              </strong>
            </h5>
          </Row>
        )}
        {toCents(remaining) === 0 && bookingMode === 'edit' && (
          <Row style={{width: '320px'}}>
            <Button
              onClick={handleUpdate}
              className="input-checkout bg-success"
              color="success"
              size="sm"
            >
              UPDATE
            </Button>
          </Row>
        )}
        {toCents(remaining) === 0 && bookingMode === 'generate' && (
          <Row style={{width: '320px'}}>
            <Button
              onClick={handleCheckout}
              className="input-checkout bg-success"
              color="success"
              size="sm"
              disabled={disabled}
            >
              DONE
            </Button>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default Payments;

import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import Master from '../Master';
import {makeSelectBookings, makeSelectState} from './selectors';
import LocalBooking from './LocalBooking';
import SyncedBooking from './SyncedBooking';
import {selectItem} from '../../Components/Cart/actions';
import {filterBookings, searchBookings} from '../../Libs/bookings';
import {fetchBookings} from './actions';
import Filters from './Filters';

import {makeSelectUserPermissions} from '../Authentication/selectors';
import TasksList from '../Tasks/TasksList';

function Bookings() {
  const dispatch = useDispatch();
  const permissions = useSelector(makeSelectUserPermissions());
  const [q, setQ] = useState('');
  const [filter, setFilter] = useState('delivery');
  const state = useSelector(makeSelectState());
  const bookings = useSelector(makeSelectBookings());

  const handleFetchBookings = () => {
    dispatch(fetchBookings());
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  useEffect(() => {
    dispatch(selectItem(-1));
  });
  const searchedBookings = useMemo(() => searchBookings(bookings, q), [
    bookings,
    q,
  ]);

  const filteredBookings = useMemo(
    () => filterBookings(searchedBookings, filter),
    [searchedBookings, filter],
  );
  if (!permissions.dashboard_access === true) {
    return <Redirect to="/" />;
  }
  return (
    <Master>
      <div className="container-fluid customer-container">
        <div className="bookings">
          <Filters
            state={state}
            filter={filter}
            handleFetchBookings={handleFetchBookings}
            q={q}
            setFilter={setFilter}
            setQ={setQ}
          />
          {filter === 'TP Orders' && (
            <div className="tasks p-0">
              <TasksList showActions={false} />
            </div>
          )}
          {filter !== 'TP Orders' &&
            filteredBookings.map((booking) => {
              if (
                booking.syncState === 'syncing' ||
                booking.syncState === 'local'
              ) {
                return (
                  <LocalBooking key={booking.bookingUnique} booking={booking} />
                );
              }
              return (
                <SyncedBooking key={booking.booking.id} booking={booking} />
              );
            })}
        </div>
      </div>
    </Master>
  );
}

export default Bookings;
